import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LayoutModule } from "@gbol/layout";
import { NavigationModule } from "@gbol/navigation";

import { AppRoutingModule } from './app.routing.module';
import {
    AppRootComponent
} from "./components";



@NgModule({
  declarations: [
    AppRootComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    AppRoutingModule,
    LayoutModule,
    NavigationModule,
  ],
  providers: [],
  bootstrap: [AppRootComponent]
})
export class AppModule
{
}
