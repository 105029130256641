import { Component, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from "@angular/animations";


@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.styl'],
  animations: [
      trigger("navigation", [
          state("visible", style({
              opacity: 1.0
          })),
          state("hidden", style({
              opacity: 0.0
          })),
          transition("visible => hidden", [
              animate("1s")
          ]),
          transition("hidden => visible", [
              animate("0.1s")
          ])
      ])
  ],
  host: {
      "(scroll)": "showNavigation()",
      "(touchend)": "showNavigation()",
      "(touchmove)": "showNavigation()",
      "(mousemove)": "resolveNavigationByMouse($event)"
  }
})
export class AppRootComponent
{
    title = 'ski-jindra';

    navigationVisibility: string = "visible";

    private currentTimeout: any;

    private rootElement: HTMLHtmlElement;

    constructor(private host: ElementRef<HTMLElement>)
    {
        this.rootElement = document.body.parentElement as HTMLHtmlElement;
        window.addEventListener("scroll", () => {
            this.showNavigation();
        });
    }

    navigate(event: MouseEvent): void
    {
        event.preventDefault();
        const target = event.target as HTMLAnchorElement;
        const anchor = target.getAttribute("href");
        this.host.nativeElement.querySelector(anchor).scrollIntoView({behavior: "smooth"})
    }

    resolveNavigationByMouse($event: MouseEvent)
    {
        if ($event.clientY < 100)
            this.showNavigation();
    }

    showNavigation(): void
    {
        this.navigationVisibility = "visible";

        if (this.currentTimeout)
            clearTimeout(this.currentTimeout);

        if (this.rootElement.scrollTop != 0)
            this.currentTimeout = setTimeout(() =>
            {
                this.navigationVisibility = "hidden";
                this.currentTimeout = null;
            }, 1000);
    }
}
